<template>
  <div>
    <v-page-title title="Billing" />
    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" color="transparent">
      <v-container>
        <v-row v-if="credits && credits.remaining_credits > 0">
          <v-col>
            <v-alert dense type="info" outlined dark class="text-center mb-0 mt-3">You currently have {{credits.remaining_credits}} credits</v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title>Purchase More Credits</v-card-title>
              <v-card-text>
                <v-alert outlined dense class="mb-5 text-center">
                  The following links will redirect you to stripe to make the purchase.
                </v-alert>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <th width="35%">5 Credits</th>
                        <td>$5</td>
                        <td align="right">
                          <v-btn color="blue-grey darken-4" dark small @click.prevent="buyCredits(5)">Buy More</v-btn>
                        </td>
                      </tr>
                      <tr>
                        <th>10 Credits</th>
                        <td>$10</td>
                        <td align="right">
                          <v-btn color="blue-grey darken-4" dark small @click.prevent="buyCredits(10)">Buy More</v-btn>
                        </td>
                      </tr>
                      <tr>
                        <th>20 Credits</th>
                        <td>$20</td>
                        <td align="right">
                          <v-btn color="blue-grey darken-4" dark small @click.prevent="buyCredits(20)">Buy More</v-btn>
                        </td>
                      </tr>
                      <tr>
                        <th>30 Credits</th>
                        <td>$30</td>
                        <td align="right">
                          <v-btn color="blue-grey darken-4" dark small @click.prevent="buyCredits(30)">Buy More</v-btn>
                        </td>
                      </tr>
                      <tr>
                        <th>40 Credits</th>
                        <td>$40</td>
                        <td align="right">
                          <v-btn color="blue-grey darken-4" dark small @click.prevent="buyCredits(40)">Buy More</v-btn>
                        </td>
                      </tr>
                      <tr>
                        <th>50 Credits</th>
                        <td>$50</td>
                        <td align="right">
                          <v-btn color="blue-grey darken-4" dark small @click.prevent="buyCredits(50)">Buy More</v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card elevation="0">
              <v-card-title>Purchase & Credit History</v-card-title>
              <v-card-text>
                <v-loader v-if="!transactions"></v-loader>
                <v-data-table
                  v-else
                  :headers="headers" 
                  :items="transactions" 
                  item-key="id"
                  :items-per-page=30
                  class="elevation-0"
                  no-data-text="Loading..."
                  :mobile-breakpoint="0"
                >
                  <template v-slot:item.quantity="{ item }">
                    <b>{{ item.quantity }}</b>
                  </template>
                  <template v-slot:item.cost="{ item }">
                    <b>{{ item.cost }}</b>
                  </template>
                  <template v-slot:item.source="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="black" v-bind="attrs" v-on="on">{{getIcon(item.source)}}</v-icon>
                      </template>
                      <span>{{item.source}}</span>
                    </v-tooltip>
                    
                  </template>
                  <template v-slot:item.created_at="{ item }">
                    <b>{{ $date.fullDateTime(item.created_at) }}</b>
                  </template>
                  <template v-slot:no-data>
                    Loading...
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>

  export default {
    name: 'Billing.vue',
    data() {
      return {
        headers: [
          { text: "", value: "source", divider: true, width: '70px' },
          { text: "Quantity", value: "quantity", divider: true, align: "center" },
          { text: "Created On", value: "created_at", width: '240px', divider: true },
        ],
      }
    },
    computed: {
      user(){
        return this.$store.getters['users/user'];
      },
      transactions(){
        return this.$store.getters['transactions/all'];
      },
      credits(){
        return this.$store.getters['transactions/credits'];
      },
    },
    async created(){
      await this.$store.dispatch("transactions/all");
    },
    methods:{
      buyCredits(quantity){
        this.$store.dispatch('transactions/insert', {
          quantity: quantity, 
          source: 'stripe'
        });
        return false;
      },
      getIcon(source){
        switch(source.toLowerCase()){
          case 'free':
            return 'mdi-gift';
          case 'stripe':
            return 'mdi-cash';
          default:
            return 'mdi-cash';
        }
      }
    }
  }
</script>

<style lang="scss">
</style>